/*
Dark #3D3F25
Light green #8CC63F
*/


body {
    background-color: #333333 !important;
    padding: 0px;
}

abbr {
    text-underline-offset: 3px;
    text-decoration-color: #777777;
}

.MuiAppBar-root {
    background-color: #3D3F25;
}

/* Dialog */

.MuiDialogTitle-root {
    background-color: #121212;
    background-image: linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16));
    color: #ffffff;
}

.MuiDialogContentText-root {
    color: #e0e0e0 !important;
    font-weight: normal !important;
}

.MuiDialogContent-root {
    background-color: #121212;
    background-image: linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16));
    color: #ffffff;
}

.MuiDialogActions-root {
    background-color: #121212;
    background-image: linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16));
    border-top: 1px solid #8CC63F !important;
}

.MuiDialogActions-root .MuiButton-textPrimary {
    color: #8CC63F;
}

.MuiDialogActions-root .MuiButton-textPrimary:hover {
    color: #ffffff;
}

/* */

.MuiTooltip-tooltip {
    font-size: larger !important;
}

.MuiPaper-root {
    background-color: #f5f5f5;
}

.MuiToolbar-root h2 {
    text-shadow: 2px 2px 10px rgba(51, 51, 51, 0.5);
}

.MuiTypography-root.MuiTypography-h6 {
    font-size: 15px;
}

/* .MuiMenu-list {
    background-color: #e0e0e0 !important;
    color: #333333;
} */

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl fieldset {
    border-color: #BEBEBE !important;
}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.Mui-focused fieldset {
    box-shadow: "0 0 0 3px #8CC63F";
}

.MuiListSubheader-root {
    background-color: #e0e0e0 !important;
}

.MuiDataGrid-root {
    border: 0px !important;
}

.MuiDataGrid-main {
    background-color: #f5f5f5;
}

.MuiDataGrid-columnSeparator--sideRight {
    color: #bebebe !important;
    padding: 2px !important;
}

@media only screen and (min-width: 600px) {
    .MuiDataGrid-cell {
        font-weight: 400;
        font-size: smaller;
    }
}

.MuiTable-root {
    display: block;
}

.MuiTableCell-root {
    padding-left: 8px !important;
    padding-right: 4px !important;

    padding-top: 8px !important;
    padding-bottom: 8px !important;

    vertical-align: middle;
}

.MuiTableRow-root:nth-child(odd) {
    background-color: #F0EFEB;
}

.MuiTableRow-root:nth-child(1) {
    border-top: 1px solid #bebebe !important;
}

.MuiTableRow-root:last-child {
    border-top: 0px !important;
    /* border: none !important; */
}

.MuiTableCell-root {
    border-bottom: 1px solid #bebebe !important;
}

.MuiTableRow-root:last-child .MuiTableCell-root {
    border-top: 0px !important;
    /* border: none !important; */
}

/* Autocomplete */

.MuiAutocomplete-clearIndicator {
    background-color: #F75D59 !important;
    margin-right: 4pt !important;
}

.MuiAutocomplete-root:hover .MuiAutocomplete-popupIndicator {
    background-color: #8CC63F !important;
}

.MuiAutocomplete-inputRoot {
    padding-left: 6px !important;
    padding-right: 22pt !important;
}

.MuiAutocomplete-input {
    font-size: smaller !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

/* 
.MuiAutocomplete-root  */

/* Data Grid */

.MuiDataGrid-columnHeadersInner {
    background-color: #F0EFEB;
}

.MuiDataGrid-footerContainer {
    background-color: #F0EFEB;
}

.MuiDataGrid-row:nth-child(1) {
    border-top: 2px solid #bebebe !important;
}

.MuiDataGrid-cell {
    border-bottom: 1px solid #bebebe !important;
}

.MuiDataGrid-row:nth-child(even) {
    background-color: #F0EFEB;
}

.MuiDataGrid-row.Mui-selected:nth-child(even) {
    background-color: rgba(140, 198, 63, 0.5) !important;
}

.MuiDataGrid-row.Mui-selected:nth-child(odd) {
    background-color: rgba(140, 198, 63, 0.3) !important;
}


.dev_jrt_daylight_components_form_slider_Slider__container {
    border-width: 1px;
    border-style: solid;
    border-color: #BEBEBE;
    border-radius: 5px;
    padding: 2px 4px 2px 2px;
    width: 100%;
    background-color: #FFFFFF;
}

.MuiSlider-root {
    position: relative !important;
    display: flex !important;
    align-items: center !important;
    clip-path: inset(0 -2px 0 0 round 3px) !important;
    padding: 1px !important;
}

.MuiSlider-rail {
    display: none !important;
}

.MuiSlider-track {
    display: block !important;
    height: 20px !important;
    background-color: #83BC41 !important;
    border: none !important;
    border-radius: 3px !important;

    box-sizing: border-box !important;
}

.MuiSlider-thumb {
    width: 16px !important;
    height: 16px !important;
    margin-left: 2px !important;
    border-radius: 3px !important;
    background-color: #8CC63F !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px 0px !important;
    z-index: 100 !important;

    box-sizing: border-box !important;
}

.MuiSlider-thumb::after {
    box-sizing: border-box !important;
}

.MuiSlider-thumb::after {
    width: 20px !important;
    top: -2px;
    height: 20px !important;
    position: relative;
    z-index: -1;
    content: '';
    background-color: #83BC41;
    border-radius: 0 3px 3px 0 !important;
    display: block;
    box-shadow: none !important;
    left: -2px;

    box-sizing: border-box !important;
}

.MuiCardContent-root:last-child {
    padding-bottom: 0px !important;
}